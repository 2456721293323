
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '../store/modules/auth'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { Note } from '@/models/message'
import dayjs from 'dayjs'
import { Journey } from '@/models/plan'
import { CompleteStatus, StandardLog, TotalData } from '@/models/practice'
import wx from 'weixin-js-sdk-ts'

@Options({
  components: {
    NaviBar
  }
})
export default class UserInfo extends Vue {
  authStore = AuthStore
  activeName = 'statistics'
  notes: Note[] = []
  monthFirstDate = ''
  monthLastDate = ''
  maxDate: any = ''
  minDate: any = ''
  journeyList: Journey[] = []
  standardLogs: StandardLog[] = []
  monthArr = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
  currentMonthStr = ''
  pickerShow = false
  isLoading = false
  columns = [
    // 第一列
    {
      values: ['2021', '2022', '2023', '2024'],
      defaultIndex: 0
    },
    // 第二列
    {
      values: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      defaultIndex: 0
    }
  ];

  currentTree: { totalDays: number, treeNum: number, treeStage: number, treeStageDays: number, treeDays: number, stageDays: number, stageleftDays: number } = {
    totalDays: 0,
    treeDays: 28,
    stageDays: 7,
    treeNum: 0,
    treeStage: 0,
    treeStageDays: 0,
    stageleftDays: 0
  }

  currentTotalData: TotalData = {
    completedDays: 0,
    noteNum: 0,
    exerciseTotalDays: 0,
    exerciseTotalDurationMin: 0
  }

  state = {
    loading: false,
    finished: false
  }

  params = {
    pageNum: 1,
    pageSize: 10,
    sort: '-createTime'
  }

  get rowHeight () {
    return (this.screenWidth - 26) / 7
  }

  get screenWidth () {
    return document.body.clientWidth
  }

  mounted () {
    const tabbar = this.$route.params.tabbar as string
    console.log('tabbar === ', tabbar)
    if (tabbar === 'diary') {
      this.activeName = 'notes'
    }
    this.config()
    this.loadNoteData()
    this.loadJourneyData()
    this.loadStandardData()
    this.loadLifeData()
    this.loadTotalData()
  }

  tapLogin () {
    wx.miniProgram.navigateTo({ url: '/pages/login/login' })
  }

  getLocalMonth (date: string) {
    return dayjs(date).month() + 1
  }

  getLocalDay (date: string) {
    return dayjs(date).date()
  }

  getDateMMDD (date: string) {
    const dateStr = dayjs(date).format('M月D日')
    if (dateStr !== 'Invalid Date') {
      return dateStr
    }
    return ''
  }

  tapDatePicker () {
    this.pickerShow = true
  }

  onConfirm (e: any) {
    console.log(e)
    this.pickerShow = false
    const nowMonth = this.monthArr.findIndex(item => item === e[1])
    const nowYear = e[0]
    const monthStartDate = new Date(nowYear, nowMonth, 1)
    const monthEndDate = new Date(nowYear, nowMonth + 1, 0)
    this.monthFirstDate = dayjs(monthStartDate).format('YYYY-MM-DD')
    this.monthLastDate = dayjs(monthEndDate).format('YYYY-MM-DD')

    console.log('this.monthFirstDate == ', this.monthFirstDate)
    console.log('this.monthLastDate == ', this.monthLastDate)
    this.currentMonthStr = e[0] + '年' + e[1]
    // 本月的结束时间
    this.maxDate = monthEndDate
    this.minDate = monthStartDate
    console.log('this.maxDate == ', this.maxDate)
    console.log('this.minDate == ', this.minDate)
    this.loadStandardData()
  }

  config () {
    const now = new Date() // 当前日期
    const nowMonth = now.getMonth() // 当前月
    const nowYear = now.getFullYear() // 当前年
    this.currentMonthStr = nowYear + '年' + this.monthArr[nowMonth]
    // 本月的开始时间
    const monthStartDate = new Date(nowYear, nowMonth, 1)
    // 本月的结束时间
    const monthEndDate = new Date(nowYear, nowMonth + 1, 0)
    this.maxDate = monthEndDate
    this.minDate = monthStartDate
    console.log('this.maxDate == ', this.maxDate)
    console.log('this.minDate == ', this.minDate)
    this.monthFirstDate = dayjs(monthStartDate).format('YYYY-MM-DD')
    this.monthLastDate = dayjs(monthEndDate).format('YYYY-MM-DD')
  }

  formatter (day: any) {
    const month = day.date.getMonth() + 1
    const date = day.date.getDate()
    const dateStr = dayjs(day.date).format('YYYY-MM-DD')
    const theDay = this.standardLogs.find((item: StandardLog) => {
      return item.statisDate === dateStr
    })
    if (theDay) {
      if (theDay.hasExercised) {
        day.type = 'start'
      } else {
        day.type = 'disabled'
      }
    } else {
      day.type = ''
    }
    // start 全部完成   end 部分完成  disabled  未完成z
    return day
  }

  loadNoteData () {
    if (this.isLoading) {
      return
    }
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
    }, 5000)
    service.get('/mindup/mini/my-data/my-note/list', {
      params: this.params
    }).then(res => {
      this.isLoading = false
      if (res.data.content.length > 0) {
        if (this.params.pageNum === 1) {
          this.notes = res.data.content
        } else {
          this.notes = this.notes.concat(res.data.content)
        }
        this.state.loading = false
      } else {
        this.state.finished = true
      }
      this.params.pageNum += 1
    })
  }

  loadJourneyData () {
    service.get('/mindup/mini/my-data/plan-journey/list', {}).then(res => {
      this.journeyList = res.data
    })
  }

  tapNote (index: number) {
    console.log(index)
    this.$router.push({
      name: 'InformationDetail',
      params: { noteId: this.notes[index].id }
    })
  }

  loadStandardData () {
    // /mini/my-data/my-standard-exercise-complete-status/list
    service.get('/mindup/mini/my-data/my-exercise-status/list', {
      params: {
        startDate: this.monthFirstDate,
        endDate: this.monthLastDate
      }
    }).then(res => {
      console.log('loadStandardDataloadStandardDataloadStandardData')
      this.standardLogs = res.data
    })
  }

  loadLifeData () {
    service.get('/mindup/mini/my-data/my-life-practice-days/get').then(res => {
      if (!res.data) {
        return
      }
      this.currentTree.totalDays = res.data.days
      const days = res.data.days
      this.currentTree.treeNum = parseInt(days / this.currentTree.treeDays + '')
      const leftDays = days % this.currentTree.treeDays
      this.currentTree.treeStage = parseInt(leftDays / this.currentTree.stageDays + '')
      const stageleftDays = leftDays % this.currentTree.stageDays
      this.currentTree.stageleftDays = stageleftDays

      console.log(this.currentTree)
    })
  }

  loadTotalData () {
    service.get('/mindup/mini/my-data/my-statistic-overview/get').then(res => {
      this.currentTotalData = res.data
    })
  }

  tapPlanInfo (planId: any) {
    this.$router.push({
      name: 'PlanDetailInfo',
      params: { planId: planId }
    })
  }
}
